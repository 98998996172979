
.about {
  display: flex;
	flex-direction: column;
	align-items: center;
  padding-bottom: 40px;
	padding-top: 40px;
}

.about > .about-flex-container > .about-text > p > a {
  color: inherit;
}

@media (max-width: 1024px) {
  .about-page {
    position: relative;
    background-color: #ecf2f8;
  }

	.about > .about-flex-container {
		display: flex;
		flex-direction: column;
		align-items: center;
    justify-content: space-around;
		height: 100%;
		width: 80%;
	}
	
  .about > .about-flex-container > .about-text {
		padding-top: 2rem;
    height: 50%;
	}
}


@media (min-width: 1024px) {
	.about-page {
		position: relative;
    background-color: #ecf2f8;
  }
  
  .about > .about-flex-container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 80%;
		margin-top: 20px;
	}
	
	.about > .about-flex-container > .about-text {
		width: 50%;
	}
}

