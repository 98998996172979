@media (min-width: 1024px) {
  .main-layout > .navbar > nav > .links { 
    display: flex;
    align-items: center;
    width: 22rem;
    justify-content: space-between;
  }
  
  .main-layout > .navbar > nav > .links > * { 
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    position: relative;
  }
  
  .main-layout > .navbar > nav > .links > *::after { 
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .main-layout > .navbar > nav > .links > *:hover::after { 
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@media (max-width: 1024px) {
  .main-layout > .navbar > nav > .links { 
    display: flex;
    align-items: center;
    width: 17rem;
    justify-content: space-between;
  }
  
  .main-layout > .navbar > nav > .links > * { 
    text-decoration: none;
    color: #ffffff;
    font-size: 18px;
    position: relative;
  }
  
  .main-layout > .navbar > nav > .links > *::after { 
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .main-layout > .navbar > nav > .links > *:hover::after { 
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.main-layout > .navbar {
  position: fixed;
  display: flex;
  justify-content: center;
  height: 75px;
  width: 100%;
  align-items: center;
	background-color: #0F1d1d;
	z-index: 9;
}

.main-layout > .navbar > nav {
  display: flex;
  justify-content: space-between;
  width: 90%;
}

.main-layout > .navbar > nav > a > img {
	filter: brightness(1);
  transition: filter 0.35s;
}

.main-layout > .navbar > nav > a > img:hover {
	filter: brightness(50%);
}
