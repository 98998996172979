.projects-page {
  position: relative;
  background-color: #faf0ef;
}


@media (min-width: 1024px) {
  .projects {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 36px;
    padding-left: 36px;
    padding-bottom: 40px;
    padding-top: 40px;
    background-color: #faf0ef
  }

  .projects > .projects-cards {
    margin-top: 36px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 90%;
  }

  .projects > .projects-text {
    width: 75%;
    margin-top: 20px;
    overflow: hidden;
  }
}

@media (max-width: 1024px) {
  .projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
    background-color: #faf0ef
  }

  .projects > .projects-cards {
    margin-top: 18px;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
		height: 90rem;
  }

  .projects > .projects-text {
    width: 75%;
    margin-top: 20px;
  }
  
}

.projects > .projects-text > p > a {
	color: inherit;
}
