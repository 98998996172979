.work-page {
  position: relative;
  background-color: #f8fbf7;
}

@media (max-width: 1024px) {
  .work {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

@media (min-width: 1024px) {
  .work {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
  }
}