.education {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 40px;
	padding-top: 40px;
  background-color: #f7f6fa
}

@media (max-width: 1024px) {
  .education > .education-photos {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 1250px;
  }
}

@media (min-width: 1024px) and (max-width: 1536px) {
  .education > .education-photos {
    padding-top: 1rem;
    display: flex;
    justify-content: space-around;
		height: 625px;
    width: 90%; 
		flex-wrap: wrap;
  }
}

@media (min-width: 1536px) {
  .education-page {
    height: 100vh;
    background-color: #f7f6fa
  }
}

.education > .slideshow > .slides-container > img:nth-child(3) {
	object-position: 50% 0%;
}

.education > .slideshow > .slides-container > img:nth-child(4) {
	object-position: 40% 40%;
}

.education > .slideshow > .slides-container > .captions > * {
  transform: translateY(-20px);
}

.education > .slideshow > .slides-container > .captions > :nth-child(2) {
  transform: translateY(0px);
  text-wrap: wrap;
}
