@media (min-width: 1024px) {
  .project-card {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.project-card {
  position: relative;
  height: 250px;
  width: 350px;
}

.project-card > img {
  height: 100%;
  width: 100%;
  object-fit:cover;
  border-radius: 10px;
}

.project-card > .project-info-box {
  position: absolute;
  height: 40%;
  width: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 38%);
  display: flex;
  justify-content: flex-start;
  border-radius: 10px;
}

.project-card > .project-info-box > .project-info-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
  height: 85%;
  width: 90%;
}

.project-card > .project-info-box > .project-info-text > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 38%;
  width: 100%;
}

.project-card > .project-info-box > .project-info-logos {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 20px;
  height: 100%;
  width: 10%;
}

.project-card > .project-info-box > .project-info-logos > img {
  height: 30%;
  object-fit: contain;
}
